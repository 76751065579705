@use "sass:string";
@use 'adventure';

@import "fancy-borders";
@import "hero";
@import "forms";

html {
  @extend .text-bg-dark;

  @media print {
    $white: white;
    $black: black;
    background-color: $white !important;
    color: $black !important;
  }
}

body {
  @extend .text-bg-body;
  min-height: 100vh;
  margin: 0;
  display: flex;
  flex-flow: column;

  --modal-backdrop-bg-rgba: rgba($modal-backdrop-bg, $modal-backgroun-opacity);

  @media print {
    $white: white;
    $black: black;
    background-color: $white !important;
    color: $black !important;
  }
}

main {
  flex: 1;
}

a[href]:not(.default):not(.visitable:visited) {
  color: $black;
}

.alternating-images {
  .row {
    @extend .align-items-center;
    @extend .my-3;

    & > :nth-child(odd) {
      @extend .col-md-5;
      @extend .col-12;
    }

    & > :nth-child(even) {
      @extend .col-md-5;
      @extend .offset-md-2;
      @extend .col-12;
    }

    &:nth-child(even) {
      @extend .flex-md-row-reverse;

      & > :nth-child(odd) {
        @extend .offset-md-2;
      }

      & > :nth-child(even) {
        @extend .offset-md-0;
      }
    }
  }
}

.testimonials {
  display: grid;
  grid-template:
    "heading"
    "body";

    @include media-breakpoint-up(md) {
      grid-template:
        "heading .   "
        "heading body"
        ".       body";
    }

  .testimonials-heading {
    grid-area: heading;
  }

  .testimonials-body {
    grid-area: body
  }

  .testimonial {
    @extend .mt-2;
    &:last-child {
      @extend .mb-5;
    }
  }


  .long {
    @extend .display-5;
    @extend .d-none;
    @extend .d-md-block;
  }

  blockquote {
    border-left: $accent1 1px solid;
    @extend .ps-2;
  }
}

@each $percent-point in (25, 40, 60, 100) {
  $percentage: $percent-point '%';

  .image-#{$percent-point} {
    img {
      width: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      aspect-ratio: string.unquote("100/#{$percent-point}");

    }
  }
}

@mixin responsive($breakpoints: $grid-breakpoints) {
  @each $breakpoint in map-keys($breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $breakpoints);

      @content($infix);
    }
  }
}

@include responsive() using ($infix) {
  @each $percent-point in (40, 60, 100) {
    $percentage: $percent-point '%';

    .image#{$infix}-#{$percent-point} {
      img {
        width: 100%;
        object-fit: cover;
        object-position: 50% 50%;
        aspect-ratio: string.unquote("100/#{$percent-point}");
      }
    }
  }
}

@include responsive() using ($infix) {
  @for $i from 1 through 12 {
    .columns#{$infix}-#{$i} {
      columns: $i;

      > section, > .section {
        break-inside: avoid-column;
      }
    }
  }
}

@each $color, $value in $theme-colors {
  .text-bg-#{$color}, .bg-#{color} {
    .h2, h2 {
      color: color-contrast($value, $color-contrast-dark: $primary, $color_contrast_light: $dark, $min-contrast-ratio: 4.5) if($enable-important-utilities, !important, null);
    }
  }
}

@each $size in (1, 2, 3, 4, 5, 6) {
  .h#{$size}, h#{$size} {
    font-weight: 900;
    @each $size in (1, 2, 3, 4, 5, 6) {
      &.display-#{$size} {
        font-weight: revert;
      }
    }
  }
}

.list-footnotes {
  list-style-type: none;

  li::marker {
    content: attr(data-marker) '  ';
    font-weight: bolder;
  }
}

.container-copy {
  @extend .container-fluid;
  padding-left: 25%;
  padding-right: 25%
}

@each $breakpoint, $container-max-width in $container-max-widths {
  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    .container-#{$breakpoint}-copy {
      padding-left: 25%;
      padding-right: 25%;
    }
  }
}

$linear-box-border-radius: var(--#{$prefix}border-radius);
$linear-box-border-width: var(--#{$prefix}border-width) !default;
$linear-box-border-color: var(--#{$prefix}border-color-translucent) !default;
$linear-box-spacer-y: $spacer;
$linear-box-spacer-x: $spacer;

.linear-box {
  --#{$prefix}linear-box-border-radius: #{$linear-box-border-radius};
  --#{$prefix}linear-box-border-width: #{$linear-box-border-width};
  --#{$prefix}linear-box-border-color: #{$linear-box-border-color};
  --#{$prefix}linear-box-spacer-y: #{$linear-box-spacer-y};
  --#{$prefix}linear-box-spacer-x: #{$linear-box-spacer-x};

  padding: var(--#{$prefix}linear-box-spacer-y) var(--#{$prefix}linear-box-spacer-x);
  border: var(--#{$prefix}linear-box-border-width) solid var(--#{$prefix}linear-box-border-color);
  @include border-radius(var(--#{$prefix}linear-box-border-radius));
}

.nav-link.active {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

img.icon {
  object-fit: cover;
  object-position: 50% 50%;
  aspect-ratio: 1/1;
  height: 2em;
  margin: -0.5em 0;
  clip-path: circle();
}

.founders {
  .card {
    @extend .h-100;
  }

  .col {
    @extend .mb-3;
  }
 }
