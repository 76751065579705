$white:         hsl(168, 25%, 98%);
$black:         #301203;
$primary:       #f26722; // $blue;
$secondary:     #ffe7d4; // $gray-600;
$tertiary:      #bda79d;
$accent1:       hsl(168, 25%, 25%); // $red;
$success:       #007300; // $green;
$info:          #02a92e; // $cyan;
$warning:       #c4fcf0; // $yellow;
$danger:        $accent1; // $red;
$light:         #bda79d; // $gray-100;
$dark:          #55433b; // $gray-900;

@import "bootstrap/mixins";
@import "bootstrap/functions";
@import "bootstrap/variables";
@import 'bootstrap/variables-dark';

$enable-negative-margins: true;

$theme-colors: map-merge($theme-colors, (
  "primary":       $primary,
  "secondary":     $secondary,
  "tertiary":      $tertiary,
  "success":       $success,
  "info":          $info,
  "warning":       $warning,
  "danger":        $danger,
  "light":         $light,
  "dark":          $dark,
  "accent1":       $accent1,
  "body":          $body-bg,
));

@import "bootstrap/maps";
@import "bootstrap/utilities";

$utilities: map-merge(
  $utilities,
  (
    fill: (
      property: 'fill',
      class: 'fill',
      values: $theme-colors
    ),
  )
);

$utilities: map-merge(
  $utilities,
  (
    "width": map-merge(
      map-get($utilities, "width"),
      (
        responsive: true
      ),
    ),
    flex: map-merge(
      map-get($utilities, 'flex'),
      (
        values: map-merge(
          map-get(map-get($utilities, 'flex'), 'values'),
          (1: 1, 0: 0)
        )
      )
    )
  )
);
$utilities: map-merge(
  $utilities,
  (
    "min-height": (
      property: height,
      responsive: true,
      class: minh,
      values: (
        25: 25%,
        50: 50%,
        75: 75%,
        100: 100%,
        auto: auto
      )
    ),
  )
);

.fill-currentcolor {
  fill: currentColor;
}

@import "bootstrap/index";
