.js-form {
  position: relative;
  .success-message {
    display: none;

    background: $light;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: scroll;
  }

  .error-message {
    display: none;

    background: $light;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    overflow: scroll;
  }
  &.successfully-submitted .success-message {
    display: block;
  }

  &.submission-failed .error-message {
    display: block;
  }
}

.js-lead-gen-form, .js-contact-form {
  @extend .js-form;
}
