@import 'bootstrap';

%-adventure-change-state {
  --timing: 1s;

  @media (prefers-reduced-motion: no-preference) {
    transition: opacity var(--timing) ease,
      display var(--timing) ease allow-discrete;

    @include media-breakpoint-up(md) {
      transition: opacity var(--timing) ease,
      visibility var(--timing) ease allow-discrete;
    }
  }
}


$adventure-default-display: block !default;

%-adventure-hidden {
  opacity: 0;
  display: none;
  @include media-breakpoint-up(md) {
    display: revert;
    visibility: hidden;
  }
}

%-adventure-visible {
  opacity: 1;
  display: revert;
  @include media-breakpoint-up(md) {
    visibility: visible;
  }
  @extend %-adventure-change-state;
}

#find-your-own-adventure {
  @include media-breakpoint-up(md) {
    display: grid;
    grid-template-rows: auto auto;
  }

  margin-top: -120px;
  padding-top: 120px;

  padding-bottom: 60px;
}

#control-buttons {
  width: 100%;

  @include media-breakpoint-up(md) {
    grid-area: 2;
    height: calc(100% + 60px);
  }

  #start-over, #go-back {
    display: none;
  }
}

.adventure.suppress-go-back:target ~ #control-buttons #go-back{
  display: none;
}

.adventure:target ~ #control-buttons {
  #start-over, #go-back {
    display: revert;
  }
}

#all:target ~ #control-buttons a[href="#all"] {
  display: none;
}

.adventure {
  @extend %-adventure-hidden;
  @include media-breakpoint-up(md) {
    grid-area: 1 / 1/ 1/ 1;
    width: 100%;
    height: 100%;
  }

  margin-top: -120px;
  padding-top: 120px;

  &.default {
    &:not(:target ~ &) {
      @extend %-adventure-visible;
    }
  }

  &:target {
    @extend %-adventure-visible;
  }

  > .row {
    @extend .g-5;
    @extend .justify-content-center;
    @extend .flex-wrap;
    @extend .mb-5;

    > .col {
      @extend .col-12;
      @extend .col-md-4;
    }
  }

  .card {
    @extend .h-100;

    &-header, &-footer {
      text-align: center;
    }
  }
}
