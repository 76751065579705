.hero {
  @extend .mb-5;
  display: grid;
  border-radius: $spacer;

  @include media-breakpoint-up(lg) {
    padding: ($spacer * 5);
    min-height: 400px;
    grid-template-areas:
    "messaging .     "
    ".         action";

  }

  @include media-breakpoint-down(lg) {
    padding: $spacer;
    min-height: 200px;
    grid-template-areas:
    "messaging"
    "action";
  }

  .messaging {
    grid-area: messaging;
  }

  .action {
    grid-area: action;
    text-align: right;
  }
}

.stack {
  position: relative;

  & > * {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
